// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    padding: 20px;
}

h1 {
    color: #333;
}

input[type="file"] {
    margin: 10px;
}

button {
    margin-top: 10px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

img {
    margin-top: 10px;
}

table {
    border-collapse: collapse;
    width: 500px !important;
    margin-top: 20px;
    margin: 20px auto;
}

th, td {
    border: 1px solid black;
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
        background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}
  
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;QACQ,yBAAyB;AACjC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".App {\n    text-align: center;\n    padding: 20px;\n}\n\nh1 {\n    color: #333;\n}\n\ninput[type=\"file\"] {\n    margin: 10px;\n}\n\nbutton {\n    margin-top: 10px;\n    padding: 10px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    cursor: pointer;\n}\n\nbutton:hover {\n    background-color: #45a049;\n}\n\nimg {\n    margin-top: 10px;\n}\n\ntable {\n    border-collapse: collapse;\n    width: 500px !important;\n    margin-top: 20px;\n    margin: 20px auto;\n}\n\nth, td {\n    border: 1px solid black;\n    padding: 10px;\n    text-align: left;\n}\n\nth {\n    background-color: #f2f2f2;\n}\n\ntr:nth-child(even) {\n        background-color: #f9f9f9;\n}\n\ntr:hover {\n    background-color: #f1f1f1;\n}\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
